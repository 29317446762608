import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

// app
import { enqueueNotification } from 'stores';
import * as utils from 'utils';

import { quoteBindAxios } from '../quoteBind.api';

// risks
export const useUpdateDeductibles = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.useDeclineQuote',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
  };

  return useMutation(
    async (params) => {
      const { values, type, definitions, id } = params;

      if (!values || isEmpty(values) || !type || !utils.generic.isValidArray(definitions) || !id) {
        throw new Error('useUpdateDeductibles: values, type, id and definitions are required');
      }

      const data = {
        ...utils.risk.parsedValues(utils.risk.filterConditionalValues(values, definitions), definitions),
        riskType: type,
        externalSourceId: id,
      };

      const response = await quoteBindAxios.patch(`/tabChange/${type}`, data);

      return response.data;
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
