import { createContext, useMemo, useState } from 'react';

export const DeductiblesContext = createContext({
  confirm: false,
  setConfirm: () => null,
  deductibleOptions: {},
  setDeductibleOptions: () => null,
  selectedDeductible: {},
  setSelectedDeductible: () => null,
  error: false,
  setError: () => null,
});

export const DeductiblesProvider = ({ children }) => {
  const [deductibleOptions, setDeductibleOptions] = useState({});
  const [selectedDeductible, setSelectedDeductible] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);

  const value = useMemo(
    () => ({ deductibleOptions, setDeductibleOptions, selectedDeductible, setSelectedDeductible, confirm, setConfirm, error, setError }),
    [deductibleOptions, selectedDeductible, confirm, error]
  );

  return <DeductiblesContext.Provider value={value}>{children}</DeductiblesContext.Provider>;
};
