import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

// app
import { enqueueNotification } from 'stores';
import * as utils from 'utils';

import { quoteBindAxios } from '../quoteBind.api';

// risks
export const useCreateDeductibles = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.risks.useDeclineQuote',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    // utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useMutation(
    async (params) => {
      try {
        const { values, type, definitions } = params;

        if (!values || isEmpty(values) || !type || !utils.generic.isValidArray(definitions)) {
          throw new Error('usePostRisk: values, type, and definitions are required');
        }

        const data = {
          ...utils.risk.parsedValues(utils.risk.filterConditionalValues(values, definitions), definitions),
          riskType: type,
        };

        const response = await quoteBindAxios.post(`/tabChange/${type}`, data);

        return response.data;
      } catch (err) {
        handleError(err);
      }
    },
    {
      onError: (err) => handleError(err),
    }
  );
};
