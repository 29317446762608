import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { MY_RISKS, quoteBindAxios, RISKS, useDeleteDraftRisk } from 'lib/quoteBind';
// app
import { enqueueNotification, hideModal } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

// risks
export const usePostRisk = () => {
  const dispatch = useDispatch();

  const { mutate: deleteDraftRisk } = useDeleteDraftRisk();
  const queryClient = useQueryClient();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.usePostRisk',
    };

    dispatch(enqueueNotification('notification.generic.request', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  const handleSuccess = (draftId, result) => {
    queryClient.invalidateQueries(RISKS);
    queryClient.invalidateQueries(MY_RISKS);

    if (!isEmpty(draftId)) {
      deleteDraftRisk({ draftId, isSubmitted: true });
    }

    dispatch(hideModal());
    dispatch(enqueueNotification('notification.saveRisk.success', 'success'));

    return result;
  };

  return useMutation(
    async (params) => {
      const { values, type, definitions, draftId = null } = params;

      if (!values || isEmpty(values) || !type || !utils.generic.isValidArray(definitions)) {
        throw new Error('usePostRisk: values, type, and definitions are required');
      }

      const data = {
        ...utils.risk.parsedValues(utils.risk.filterConditionalValues(values, definitions), definitions),
        riskType: type,
      };

      const result = await quoteBindAxios.post(`/risks`, data);

      return { result, draftId: draftId };
    },
    {
      onSuccess: ({ draftId, result }) => handleSuccess(draftId, result),
      onError: (err) => handleError(err),
      keepPreviousData: true,
    }
  );
};
