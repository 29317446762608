/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import * as constants from 'consts';

import { Box, Card, CardActions, CardContent, CardHeader, LinearProgress, makeStyles, Typography, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockIcon from '@material-ui/icons/Block';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Skeleton from '@material-ui/lab/Skeleton';
import Autorenew from '@material-ui/icons/Autorenew';

// app
import { Avatar, Button, FormGrid, PopoverMenu, Restricted, Tooltip } from 'components';
import { quoteInformation } from 'modules/QuoteBind/RiskQuotes/QuoteCard';

import { ROLE_BROKER, ROLE_PRODUCER } from 'consts';
import { useDownloadQuote, useGetCancellationReasons, useRenewPolicy, useUnBindPolicy } from 'lib/quoteBind';
import { showModal } from 'stores';
import * as utils from 'utils';

import styles from './PolicyCard.style';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
    minHeight: 262,
  },

  title: {
    color: 'black!important',
  },
  value: {
    color: 'black!important',
    fontWeight: 600,
    margin: 0,
  },
}));

const Hint = ({ hint }) => (
  <Typography variant="body2" style={{ color: 'black', fontWeight: 600, margin: 0 }}>
    {hint}
  </Typography>
);

export const QuoteLine = ({ title, value, editIcon, titleVariant = 'body2', indent = false }) => {
  const classes = useStyles();

  return (
    <>
      <FormGrid
        item
        xs={4}
        style={{
          paddingLeft: indent ? 20 : 'initial',
        }}
      >
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      </FormGrid>
      <FormGrid item xs={8}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={titleVariant} className={classes.value}>
            {value}
          </Typography>
          {editIcon || null}
        </Box>
      </FormGrid>
    </>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
  },
  colorPrimary: {
    backgroundColor: theme.palette.success.main,
  },
  bar: {
    backgroundColor: theme.palette.success.dark,
  },
}))(LinearProgress);

const getQuoteInfo = (endorsements, policy) => {
  let quoteInfo = [];

  if (endorsements?.length) {
    const lastEndorsement = endorsements[endorsements.length - 1];
    quoteInfo = lastEndorsement?.summaryValues ? lastEndorsement.summaryValues : policy?.summaryValues;
  } else {
    quoteInfo = policy?.summaryValues || [];
  }

  return quoteInfo;
};

export const PolicyCard = ({
  policy,
  endorsements,
  inceptionDate,
  expiryDate,
  isCanceled = false,
  isExtended = false,
  hasAnyEndorsements = false,
  isExpired = false,
  isCancelation = false,
  hasSurplusLineTax = false,
  hasBackdateCancellation = false,
}) => {
  const classes = makeStyles(styles, { name: 'PolicyCard' })();
  const dispatch = useDispatch();

  const { data: cancellationReasons } = useGetCancellationReasons(policy?.riskType, { enabled: Boolean(policy?.riskType) });
  const { mutate: unBindPolicy } = useUnBindPolicy();
  const { mutateAsync: downloadRiskQuote } = useDownloadQuote();

  const [isDownloading, setIsDownloading] = useState({
    inProgress: false,
    draftInvoice: false,
  });

  const isDownloadingDraftInvoice = isDownloading?.inProgress && isDownloading?.draftInvoice;
  const isDownloadingPolicy = isDownloading?.inProgress && !isDownloading?.draftInvoice;

  const carrierName = get(policy, 'carrierName');
  const quotePremium = isNumber(policy?.commission?.revisedPremium) ? policy.commission.revisedPremium : policy.premium;
  const hasTemplate = policy?.hasTemplate;
  const hasDraftInvoice = policy?.hasDraftInvoice;
  const hasPreBindInformation = policy?.hasPreBindInformation;

  const policyResponse = policy?.response;
  const policyNumber =
    policy.facility.brokerCode && policy.declarationNumber ? `${policy.facility.brokerCode}${policy.declarationNumber}` : '';

  const hasEndorsements = endorsements?.length > 0;
  const endorsementPremiumTotal = endorsements?.reduce((acc, endorsement) => acc + endorsement.proRata, 0) || 0;

  const premiumValue = `${policy?.currency ? utils.string.t(policy.currency) : ''} ${utils.string.t('format.number', {
    value: { number: quotePremium + endorsementPremiumTotal, default: '-' },
  })}`;

  const policyPremiumValue = `${policy?.currency ? utils.string.t(policy.currency) : ''} ${utils.string.t('format.number', {
    value: { number: quotePremium, default: '-' },
  })}`;

  const surplusLineTax = `${policy?.currency ? utils.string.t(policy.currency) : ''} ${utils.string.t('format.number', {
    value: { number: policy?.quoteValues?.surplusLineTax, default: '-' },
  })}`;

  const effectiveFrom = isCancelation ? inceptionDate : policyResponse?.effectiveFrom;
  const effectiveTo = isCancelation ? expiryDate : policyResponse?.effectiveTo;

  const today = utils.date.today();
  const { earliestBindPeriod } = policy?.facility || 0;
  const effectivePastPercent =
    utils.date.datePercent(policyResponse.effectiveFrom, policyResponse.effectiveTo, today) < 100
      ? utils.date.datePercent(policyResponse.effectiveFrom, policyResponse.effectiveTo, today)
      : 100;

  const wasBoundInPast = utils.date.isSameOrBefore(policyResponse.effectiveFrom, policyResponse.responseDate);
  const showUnBind =
    policy?.canCurrentUserDismissIssues &&
    (wasBoundInPast
      ? utils.date.diffDays(today, policyResponse.responseDate) <= earliestBindPeriod
      : utils.date.diffDays(today, policyResponse.effectiveFrom) <= earliestBindPeriod);

  const showCancel = policy?.canCurrentUserDismissIssues && !isCanceled;
  const showExtend =
    policy?.canCurrentUserDismissIssues &&
    utils.date.diffDays(policyResponse.effectiveTo, today) <= earliestBindPeriod &&
    !isExtended &&
    !isCanceled &&
    !isExpired;

  const showRenew = false && utils.date.isSameOrBefore(utils.date.subtractDays(expiryDate, earliestBindPeriod));

  const quoteInfo = getQuoteInfo(endorsements, policy);

  const handleDownload = async (obj) => {
    setIsDownloading({ inProgress: true, draftInvoice: obj?.type === 'draftInvoice' });
    await downloadRiskQuote(obj);
    setIsDownloading({ inProgress: false, draftInvoice: false });
  };
  const handleCancelPolicy = (policyParam) => {
    dispatch(
      showModal({
        component: 'EXTEND_CANCEL_POLICY',
        props: {
          title: `Cancel - ${policyNumber}`,
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            minDate: hasBackdateCancellation ? utils.date.formatDateWithParameter(policyResponse.effectiveFrom) : utils.date.today(),
            maxDate: utils.date.addDays(utils.date.endOf(policyResponse.effectiveTo), -1),
            policyId: policyParam.id,
            cancellationReasons: cancellationReasons || [],
            hint: <Hint hint="This will create a new cancel endorsement and change the policy end date." />,
            isExtend: false,
          },
        },
      })
    );
  };

  const handleExtendPolicy = (policyParam) => {
    dispatch(
      showModal({
        component: 'EXTEND_CANCEL_POLICY',
        props: {
          title: `Extend - ${policyNumber}`,
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            minDate: utils.date.startOf(utils.date.addDays(policyResponse.effectiveTo, 1)),

            maxDate: utils.date.endOf(utils.date.addDays(policyResponse.effectiveTo, earliestBindPeriod)),
            policyId: policyParam.id,
            hint: <Hint hint="This will create a new endorsement and change the policy end date." />,
            isExtend: true,
          },
        },
      })
    );
  };

  const handleUnBoundPolicy = (policyParam) => {
    dispatch(
      showModal({
        component: 'CONFIRM_UNBOUND',
        props: {
          title: `Unbind - ${policyNumber}`,
          fullWidth: true,
          maxWidth: 'xs',
          disableAutoFocus: true,
          componentProps: {
            effectiveTo: policyResponse.effectiveTo,
            policyNumber,
            submitHandler: () => {
              unBindPolicy(policyParam);
            },
            isConfirm: true,
            hint: (
              <Hint hint={` Warning: This will un-bind the policy${hasAnyEndorsements ? ` and delete all associated endorsements` : ``}`} />
            ),
          },
        },
      })
    );
  };
  const handleRenewPolicy = () => {
    const { riskType, id: riskId, risk: riskData } = { ...(risk ?? {}) };
    const modalSubtitle = utils.string.t('app.renewal');
    const modalTitle = `${modalSubtitle} - ${risk?.insured?.name}`;

    const renewalRiskData = {
      ...riskData,
      newRenewal: 'RENEWAL',
      inceptionDate: utils.date.addYears(riskData?.inceptionDate, 1),
      expiryDate: utils.date.addYears(riskData?.expiryDate, 1),
    };

    dispatch(
      showModal({
        component: 'ADD_EDIT_QUOTE_BIND',
        props: {
          title: modalTitle,
          fullWidth: true,
          disableBackdropClick: true,
          enableFullScreen: true,
          maxWidth: 'xl',
          componentProps: {
            product: { value: riskType },
            riskData: renewalRiskData,
            riskId,
            renewal: true,
          },
        },
      })
    );
  };

  const subheader = <Box style={{ display: 'flex', alignItems: 'center' }}>{carrierName}</Box>;

  return (
    <Card classes={{ root: classes.card }} data-testid="policy-card">
      <CardHeader
        avatar={<Avatar icon={HowToVoteIcon} size={32} border />}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3" className={classes.cardFacility}>
              {`${policyNumber}`}
            </Typography>
            {quoteInfo?.length || showUnBind ? (
              <Box display="flex" flex="1" justifyContent="flex-end">
                {quoteInfo?.length ? (
                  <Tooltip title={quoteInformation(quoteInfo, true)} rich interactive style={{ display: 'flex', alignItems: 'center' }}>
                    <InfoOutlinedIcon classes={{ root: classes.infoIcon }} data-testid="quote-summary-icon" />
                  </Tooltip>
                ) : null}
                {showUnBind ? (
                  <PopoverMenu
                    id="risk-unbind-menu"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    items={[
                      {
                        id: 1,
                        label: utils.string.t('risks.unBind'),
                        callback: () => handleUnBoundPolicy(policy),
                      },
                    ]}
                  />
                ) : null}
              </Box>
            ) : null}
          </Box>
        }
        subheader={subheader}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        <BorderLinearProgress variant="determinate" value={effectivePastPercent} />
        <Box>
          {hasEndorsements ? (
            <FormGrid container spacing={1} alignItems="center" style={{ padding: 16 }}>
              <QuoteLine title={utils.string.t('app.policy')} value={policyPremiumValue} titleVariant="h6" />
              {hasSurplusLineTax ? (
                <Restricted include={[ROLE_BROKER, ROLE_PRODUCER]}>
                  <QuoteLine title={utils.string.t('risks.surplusLineTax')} value={surplusLineTax} />
                </Restricted>
              ) : null}
              <QuoteLine title={utils.string.t('app.endorsement_plural')} value={` `} titleVariant="h5" />
              {endorsements.map((endorsement) => {
                const endorsementProRataValue = `${policy?.currency ? utils.string.t(policy.currency) : ''} ${utils.string.t(
                  'format.number',
                  {
                    value: { number: endorsement.proRata, default: '-' },
                  }
                )}`;
                const value = (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" style={{ color: 'black', fontWeight: 600, margin: 0 }}>
                      {endorsementProRataValue}
                    </Typography>
                  </Box>
                );
                return (
                  <QuoteLine
                    key={endorsement.id}
                    title={utils.string.t('format.date', {
                      value: { date: endorsement.effectiveFrom, format: 'll', default: '-' },
                    })}
                    value={value}
                    titleVariant="body2"
                    indent
                  />
                );
              })}
            </FormGrid>
          ) : null}
          <FormGrid container spacing={1} alignItems="center" style={{ backgroundColor: '#e6e6e6', padding: 16, marginBottom: '15px' }}>
            <QuoteLine title={utils.string.t('app.premium')} value={premiumValue} titleVariant="h5" />
          </FormGrid>
          <FormGrid container spacing={1} alignItems="center" style={{ padding: 16 }}>
            <QuoteLine
              title={utils.string.t('risks.effectiveFrom')}
              value={utils.string.t('format.date', {
                value: { date: effectiveFrom, format: 'll', default: '-' },
              })}
            />

            <QuoteLine
              title={utils.string.t('risks.effectiveTo')}
              value={utils.string.t('format.date', {
                value: { date: effectiveTo, format: 'll', default: '-' },
              })}
            />
          </FormGrid>
        </Box>
      </CardContent>
      <CardActions disableSpacing classes={{ root: classes.cardActions }}>
        {hasTemplate ? (
          <Box px={1} pb={0.5} ml="auto" display="flex" flexWrap="wrap" flex={1} justifyContent="flex-start">
            {hasDraftInvoice ? (
              <Box mr={1} mb={1} position="relative">
                <Tooltip title={hasPreBindInformation ? `` : utils.string.t('risks.draftInvoiceErrorHint')} placement="top" rich>
                  <Button
                    size="xsmall"
                    icon={!isDownloadingDraftInvoice ? ReceiptIcon : null}
                    color="primary"
                    text={utils.string.t('risks.draftInvoice')}
                    nestedClasses={{ btn: isDownloadingDraftInvoice ? classes.buttonDownloading : '', icon: classes.icon }}
                    disabled={!hasPreBindInformation || isDownloading.inProgress}
                    onClick={() =>
                      handleDownload({
                        id: policy.id,
                        type: 'draftInvoice',
                      })
                    }
                  />
                </Tooltip>
                {isDownloadingDraftInvoice ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
              </Box>
            ) : null}

            <Box mb={1} position="relative">
              <Button
                size="xsmall"
                icon={!isDownloadingPolicy ? PictureAsPdfIcon : null}
                color="secondary"
                text={utils.string.t('risks.downloadPolicy')}
                nestedClasses={{ btn: isDownloadingPolicy ? classes.buttonDownloading : '', icon: classes.icon }}
                disabled={isDownloading.inProgress}
                onClick={() =>
                  handleDownload({
                    id: policy.id,
                    type: 'policy',
                  })
                }
              />
              {isDownloadingPolicy ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
            </Box>
          </Box>
        ) : null}
        {isExpired ? null : (
          <Box px={1} pb={0.5} ml="auto" display="flex" flexWrap="wrap" justifyContent="flex-end" alignItems="flex-start">
            {showCancel ? (
              <Button
                size="xsmall"
                icon={BlockIcon}
                text={utils.string.t('risks.cancelPolicy')}
                nestedClasses={{ btn: classes.buttonCancel }}
                onClick={() => handleCancelPolicy(policy)}
              />
            ) : null}
            {showExtend ? (
              <Button
                size="xsmall"
                icon={PostAddIcon}
                nestedClasses={{ btn: classes.buttonExtend }}
                text={utils.string.t('risks.extendPolicy')}
                onClick={() => handleExtendPolicy(policy)}
              />
            ) : null}
          </Box>
        )}
        {showRenew && (
          <Restricted include={[constants.ROLE_BROKER, constants.ROLE_PRODUCER]}>
            <Button
              size="xsmall"
              icon={Autorenew}
              text={utils.string.t('risks.renewPolicy')}
              nestedClasses={{ btn: classes.buttonRenew }}
              disabled={renewInProgress}
              onClick={handleRenewPolicy}
            />
          </Restricted>
        )}
      </CardActions>
    </Card>
  );
};

export const PolicyCardSkeleton = ({ height = 'auto' }) => {
  const classes = useStyles();

  return (
    <Card data-testid="skeleton-policy-card" classes={{ root: classes.root }} style={{ height }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />

      <CardContent>
        <>
          <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={20} />
        </>
      </CardContent>
      <CardContent classes={{ root: classes.cardContentCommission }}>
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} />
      </CardContent>
    </Card>
  );
};
