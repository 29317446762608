import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'autosuggest-highlight/parse';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from 'components';

import { BARRIER_ISLAND_LIMIT, GOOGLE_LOCATIONS_URL, RISK_LOCATIONS_ACCURACY } from 'consts';
import { useGetAddress, useGetDistanceToCoastRequest } from 'lib/quoteBind';
import { enqueueNotification, selectSelectedProduct } from 'stores';
import * as utils from 'utils';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  autocomplete: {
    width: 300,
  },
}));

export default function LocationAutocomplete({
  title,
  buttonLabel,
  placeholder,
  handleAdd,
  componentRestrictions,
  getDTC = false,
  prepopulatedFields,
  isDisabled = false,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [isAdding, setIsAdding] = React.useState(false);
  const [, setSelectedValue] = React.useState(null);
  const endpoint = useSelector((state) => get(state, 'config.vars.endpoint'));
  const loaded = React.useRef(false);
  const dispatch = useDispatch();
  const { mutateAsync: getDistanceToCoast } = useGetDistanceToCoastRequest();
  const { mutateAsync: getRiskAddress } = useGetAddress();
  const product = useSelector(selectSelectedProduct);

  const getPrepopulatedData = async (product, field, geocodedData) => {
    const { dependants, dataIncludeCustom, dataSource, dataIndex, valueIndex, prepopulated, partialMatch, defaultValue } = field;
    let restrictBy = ``;
    for (const dependant of dependants) {
      const [dependantIndex, dependantValue] = dependant.split(':');
      restrictBy = `${restrictBy}${dependantIndex}:${geocodedData[dependantValue]}`;
    }
    const response = prepopulated
      ? await utils.api.get({
          endpoint: endpoint.auth,
          path: `api/v1/products/${product}/data/${dataIndex}`,
          params: {
            dataSource,
            valueIndex,
            restrictBy: encodeURIComponent(restrictBy),
            ...(dataIncludeCustom ? { includeCustom: dataIncludeCustom } : {}),
            ...(typeof partialMatch === 'boolean' && { partialMatch }),
            ...(dataSource && defaultValue && { defaultValue }),
          },
        })
      : await utils.api.get({
          endpoint: endpoint.auth,
          path: `api/v1/products/${product}/prepopulated/data/${dataIndex}`,
          params: {
            dataSource,
            valueIndex,
            restrictBy: encodeURIComponent(restrictBy),
            ...(dataIncludeCustom ? { includeCustom: dataIncludeCustom } : {}),
          },
        });
    const data = await utils.api.handleResponse(response);
    return { result: data, field };
  };

  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(GOOGLE_LOCATIONS_URL, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, componentRestrictions]);

  const getBuildingObject = (response, distanceToCoastResult) => ({
    locationName: response?.locationName ? response.locationName : response?.inputAddress.split(',')[0] || '',
    city: response?.city || '',
    zip: response?.zip || '',
    county: response?.county || '',
    country: response?.country || '',
    state: response?.state || '',
    streetAddress: response?.streetNumber
      ? `${response.streetNumber} ${response?.streetAddress}`
      : response?.streetAddress
      ? `${response.streetAddress}`
      : '',
    formattedAddress: response?.outputAddress || '',
    latitude: response?.lat || '',
    longitude: response?.lng || '',
    ...(getDTC && {
      distanceToCoast: distanceToCoastResult?.distanceInMiles,
      distanceToCoastInitialValue: distanceToCoastResult?.distanceInMiles,
      onBarrierIsland: distanceToCoastResult?.distanceInMiles < BARRIER_ISLAND_LIMIT ? null : false,
      inOuterBanksNc: distanceToCoastResult?.distanceInMiles < BARRIER_ISLAND_LIMIT && response?.state === 'North Carolina' ? null : false,
    }),
  });

  const getAddressDetails = async (address) => {
    const response = await getRiskAddress({ searchTerm: address, componentRestrictions });

    if (response?.accuracy !== 'ROOFTOP')
      if (!RISK_LOCATIONS_ACCURACY.includes(response?.accuracy)) return { error: 'NO_ACCURATE_RESULT', address };

    const location = { lng: response.lng, lat: response.lat };
    const distanceToCoastResult = getDTC ? await getDistanceToCoast(location) : null;

    const prePopulatedValues = {};
    if (prepopulatedFields?.length) {
      const asyncRequests = prepopulatedFields.map(async (field) => getPrepopulatedData(product, field, response));

      return Promise.all(asyncRequests).then((results) => {
        results.forEach((res) => {
          if (!res.field.multi && res.result?.length === 1) {
            prePopulatedValues[res.field.name] = res.result[0] || res.field.defaultValue || '';
          } else {
            prePopulatedValues[res.field.name] = res.result || '';
          }
        });
        let result = getBuildingObject(response, distanceToCoastResult);
        result = { ...result, ...prePopulatedValues };
        return result;
      });
    }
    return getBuildingObject(response, distanceToCoastResult);
  };

  const handleAddAddress = async () => {
    setIsAdding(true);

    if (value?.description) {
      const result = await getAddressDetails(value.description);

      if (result?.error !== 'NO_ACCURATE_RESULT') {
        handleAdd(result);
        setSelectedValue({ ...result, description: value.description });
      } else {
        dispatch(
          enqueueNotification(`${result?.address} ${utils.string.t('products.multiLocation.buildingError')}`, 'error', { delay: 6000 })
        );
      }
    }
    setIsAdding(false);
  };

  const handelAnimationStart = (e) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill' || e.animationName === 'mui-auto-fill-cancel') {
      if (autofilled) {
        setOpen(false);
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" data-testid="location-autocomplete">
      <Box>
        <Autocomplete
          id="google-autocomplete"
          open={open}
          onOpen={openPopper}
          onClose={closePopper}
          className={classes.autocomplete}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={title}
              placeholder={placeholder}
              variant="outlined"
              fullWidth
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleAddAddress();
                }
              }}
              inputProps={{
                ...params.inputProps,
                onAnimationStart: handelAnimationStart,
              }}
            />
          )}
          renderOption={(option) => {
            const matches = option?.structured_formatting?.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches?.map((match) => [match.offset, match.offset + match?.length])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </Box>
      <Box ml={2.5} mt={2}>
        <Button
          disabled={isAdding || !value || isDisabled}
          color="primary"
          size="small"
          icon={isAdding ? null : AddIcon}
          text={buttonLabel}
          onClick={handleAddAddress}
          data-testid="add-location-button"
        />
      </Box>
    </Box>
  );
}
