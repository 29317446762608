import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { RISK_QUOTE_STATUS_QUOTED } from 'consts';
import { usePutEndorsementQuote } from 'lib/quoteBind';
import * as utils from 'utils';

// app
import { EditRiskQuoteView } from './EditRiskQuote.view';
import { isValid } from 'date-fns';

export const EditEndorsementQuote = ({ quote, hasEndorsementAdditionalPremium, handleClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: updateEndorsementQuotePremium } = usePutEndorsementQuote();

  const quoteStatus = quote.response ? quote.response.responseStatus : quote.status;
  const isQuoted = quoteStatus === RISK_QUOTE_STATUS_QUOTED;

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await updateEndorsementQuotePremium({
      endorsementId: quote.id,
      premium: data.premium,
      overriddenProRata: quote.proRata,
      validUntil: data.validUntil,
      additionalPremiums: [{ description: 'Additional Premium', type: 'PRE_COMMISSION', premium: data.additionalPremium }],
    });
    if (result) handleCancel();
    else setIsSubmitting(false);
  };

  const handleSubmitAndAccept = async (data) => {
    setIsSubmitting(true);
    const result = await updateEndorsementQuotePremium({
      endorsementId: quote.id,
      premium: data.premium,
      validUntil: data.validUntil,
      accept: true,
    });
    if (result) handleCancel();
    else setIsSubmitting(false);
  };

  const handleCancel = () => {
    if (utils.generic.isFunction(handleClose)) {
      handleClose();
    }
  };

  // abort
  if (!quote || !quote.id) return null;

  const fields = [
    [
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'number',
        name: 'premium',
        value: quote.premium,
        label: `${utils.string.t('risks.annualPremium')}${quote?.currency ? ` (${quote?.currency.trim()})` : ``}`,
        validation: Yup.number()
          .nullable()
          .transform(function (value) {
            return this.isType(value) ? (Number.isNaN(value) ? null : value) : null;
          })
          .required(utils.string.t('validation.required')),
        disabled: isSubmitting,
      },

      {
        gridSize: { xs: 12, sm: 6 },
        type: 'datepicker',
        name: 'validUntil',
        label: utils.string.t('risks.quoteValidUntil.label'),
        value: quote.validUntil,
        outputFormat: 'iso',
        muiPickerProps: {
          minDate: utils.date.tomorrow(),
        },
        muiComponentProps: {
          fullWidth: true,
          disabled: isSubmitting,
        },
        validation: Yup.string()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .required(utils.string.t('risks.quoteValidUntil.required'))
          .nullable()
          .test('valid-date', utils.string.t('risks.quoteValidUntil.validDate'), (value) => isValid(new Date(value))),
      },
      ...(hasEndorsementAdditionalPremium
        ? [
            {
              gridSize: { xs: 12 },
              type: 'legend',
              label: `Additional Fees (Pre Commission)`,
            },
            {
              gridSize: { xs: 12, sm: 6 },
              type: 'number',
              name: 'additionalPremium',
              value:
                quote.additionalPremiums?.find((item) => item?.description === utils.string.t('risks.additionalPremium'))?.premium || 0,
              label: `${utils.string.t('risks.additionalPremium')}${quote?.currency ? ` (${quote?.currency.trim()})` : ``}`,
              validation: Yup.number()
                .nullable()
                .transform(function (value) {
                  return this.isType(value) ? (Number.isNaN(value) ? null : value) : null;
                })
                .required(utils.string.t('validation.required')),
              disabled: isSubmitting,
            },
          ]
        : []),
    ],
    {
      type: 'hidden',
      name: 'riskId',
      value: quote.riskId,
    },
  ];

  const updateAndAccept = {
    name: 'secondary',
    label: utils.string.t('risks.updateAndAccept'),
    handler: handleSubmitAndAccept,
    disabled: isSubmitting,
    tooltip: {
      title: utils.string.t('risks.updateLegend.submitAccept'),
    },
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('risks.update'),
      handler: handleSubmit,
      disabled: isSubmitting,
      tooltip: {
        title: utils.string.t('risks.updateLegend.submit'),
      },
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleCancel,
      disabled: isSubmitting,
    },
  ];

  return <EditRiskQuoteView fields={fields} actions={isQuoted ? actions : [updateAndAccept, ...actions]} />;
};

EditEndorsementQuote.propTypes = {
  quote: PropTypes.object.isRequired,
  hasEndorsementAdditionalPremium: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default EditEndorsementQuote;
