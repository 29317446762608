import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';

// mui
import { makeStyles } from '@material-ui/core';

import {
  INSUREDS,
  LARGE_SIZE,
  REINSUREDS,
  useGetClientDetailMutation,
  useGetInsuredDetailMutation,
  useGetInsureds,
  useGetReInsureds,
} from 'lib/quoteBind';
import { selectIsProducer, selectSelectedProduct, selectUser, setPartyClient } from 'stores';
import * as utils from 'utils';

import { AddRiskFormFieldView } from './AddRiskFormField.view';
import { useFieldCalculation } from './utils';

// app
import styles from './AddRiskFormField.styles';

const AddRiskFormField = ({ field, formProps, handleUpdateObject }) => {
  const dispatch = useDispatch();
  const type = useSelector(selectSelectedProduct);

  const { control, register, watch, errors, setValue, trigger } = formProps;
  const classes = makeStyles(styles, { name: 'AddRiskFormField' })();
  const { data: insuredsContent } = useGetInsureds(INSUREDS, { size: LARGE_SIZE, productCode: type });
  const { data: reinsuredsContent } = useGetReInsureds(REINSUREDS, { size: LARGE_SIZE, productCode: type });

  const { mutateAsync: getClientDetail } = useGetClientDetailMutation();
  const { mutateAsync: getInsuredDetail } = useGetInsuredDetailMutation();
  const { mutateAsync: getReInsuredDetail } = useGetInsuredDetailMutation();

  const insureds = insuredsContent?.content || [];
  const reinsureds = reinsuredsContent?.content || [];

  const riskProductsSelected = useSelector((store) => store.risk.products.selected);
  const user = useSelector(selectUser);
  const isProducer = useSelector(selectIsProducer);
  const endpoint = useSelector((state) => get(state, 'config.vars.endpoint'));
  const { auth } = user;
  const { clientId: client, countryOfOrigin, ...values } = useWatch({ control });

  const producerPartyOptions = {
    insureds: insureds.filter((insured) => insured.clientId === client?.id) || [],
    reinsureds: reinsureds.filter((reinsured) => reinsured.clientId === client?.id) || [],
  };

  useFieldCalculation({ field, control, riskType: riskProductsSelected, setValue });

  useEffect(async () => {
    if (field.selectCurrency && countryOfOrigin) {
      const response = await utils.api.get({
        token: auth.accessToken,
        endpoint: endpoint.auth,
        path: `api/v1/products/currency/${countryOfOrigin}`,
      });
      const responseData = await utils.api.handleResponse(response);
      setValue(field.name, responseData?.result);
    }
  }, [field.selectCurrency, countryOfOrigin]);

  const value = useWatch({ control, name: field.name });

  useEffect(() => {
    if (field.name === 'clientId') {
      dispatch(setPartyClient(client));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  // abort
  if (!field || !field.name || !field.type) return null;
  if (!formProps || !formProps.control) return null;

  const checkboxProps = field.type === 'checkbox' ? { register, watch, label: field?.title ?? '' } : {};
  const selectProps = field.type === 'select' ? { nestedClasses: { root: classes.select } } : {};

  field.error = get(errors, field.name);

  if (field.type === 'autocomplete') {
    field.handleUpdate = (id, value) => {
      setValue(id, value);
      trigger(id);
    };
  }

  if (field.name === 'insuredId' || field.name === 'reinsuredId') {
    field.disabled = isProducer && !client;
    if (isProducer && client) {
      field.options =
        field.name === 'insuredId'
          ? [...utils.form.getSelectOptions('insureds', { ...producerPartyOptions })]
          : [...utils.form.getSelectOptions('reinsureds', { ...producerPartyOptions })];
    }
  }

  if (field?.targetField) {
    field.callback = async (e, item) => {
      switch (field.name) {
        case 'clientId': {
          const client = await getClientDetail(item?.value);
          utils.generic.isFunction(handleUpdateObject) && handleUpdateObject(field.targetField, client);
          break;
        }
        case 'insuredId': {
          const insured = await getInsuredDetail({ id: item?.value });
          utils.generic.isFunction(handleUpdateObject) && handleUpdateObject(field.targetField, insured);
          break;
        }
        case 'reinsuredId': {
          const reInsured = await getReInsuredDetail({ id: item?.value, reInsured: true });
          utils.generic.isFunction(handleUpdateObject) && handleUpdateObject(field.targetField, reInsured);
          break;
        }

        default:
          break;
      }
    };
  }

  if (field.name === 'inceptionDate') {
    field.handleUpdate = (id, value) => {
      if (Object.keys(values).includes('expiryDate')) {
        setValue('expiryDate', moment(value).add(1, 'y').toISOString());
        trigger('expiryDate');
      }
    };
  }

  return (
    <AddRiskFormFieldView
      field={field}
      control={control}
      setValue={setValue}
      value={value}
      checkboxProps={checkboxProps}
      selectProps={selectProps}
    />
  );
};

AddRiskFormField.propTypes = {
  field: PropTypes.object.isRequired,
  formProps: PropTypes.object.isRequired,
  handleUpdateObject: PropTypes.func,
};

export default AddRiskFormField;
