import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';

import { Box, CircularProgress, Grid } from '@material-ui/core';

import { ReactComponent as Error } from 'assets/svg/error.svg';
import { Button, Empty, FormSelect } from 'components';

import { useCreateDeductibles, useUpdateDeductibles } from 'lib/quoteBind';
import { enqueueNotification, showModal } from 'stores';
import * as utils from 'utils';

import { DeductiblesContext } from './Deductibles.context';

const UNSET_OPTION = {
  limit: null,
  deductible: 'Excluded',
  premium: null,
  additionalPremium: null,
  annualizedPremium: null,
  selected: false,
};

const Deductibles = ({ field, formProps, productType, fields }) => {
  const dispatch = useDispatch();
  const { deductibleOptions, setDeductibleOptions, selectedDeductible, setSelectedDeductible, confirm, setConfirm, error, setError } =
    useContext(DeductiblesContext);

  const { setValue, trigger, getValues } = formProps;

  const { mutateAsync: createDeductibles, isLoading: isCreating } = useCreateDeductibles();
  const { mutateAsync: updateDeductibles, isLoading: isUpdating } = useUpdateDeductibles();

  const isLoading = isCreating || isUpdating;

  const handleSelect = (key, value) => {
    setSelectedDeductible({ ...selectedDeductible, [key]: value });
  };

  const handleCalculateClick = async () => {
    const isValid = await trigger();
    if (!isValid) {
      dispatch(enqueueNotification('Please fill in all required fields', 'error'));
      return;
    }
    dispatch(
      showModal({
        type: 'CONFIRM',
        component: 'CONFIRM',
        props: {
          title: 'Are you sure you want to calculate the deductibles?',
          subtitle: 'This process will take a while to complete. Please question set is correct before proceeding.',
          fullWidth: true,
          maxWidth: 'sm',
          disableEscapeKey: true,
          componentProps: {
            cancelLabel: utils.string.t('app.cancel'),
            confirmLabel: utils.string.t('app.confirm'),
            submitHandler: async () => {
              setConfirm(true);
              const response = await (async () => {
                if (getValues(`${field.name}_externalSourceId`)) {
                  return updateDeductibles({
                    values: getValues(),
                    type: productType,
                    definitions: fields,
                    id: getValues(`${field.name}_externalSourceId`),
                  });
                }
                return createDeductibles({ values: getValues(), type: productType, definitions: fields });
              })();

              if (response?.externalSourceId) {
                const { externalSourceId, ...deductibles } = response;
                const result = Object.keys(deductibles).reduce((acc, key) => {
                  if (deductibles[key]) {
                    acc[key] = deductibles[key];
                  }
                  return acc;
                }, {});
                setDeductibleOptions(result);
                setValue(`${field.name}_externalSourceId`, externalSourceId);
              } else {
                setError(true);
              }
            },
          },
        },
      })
    );
  };

  useEffect(() => {
    const filteredSelectedDeductible = Object.keys(selectedDeductible).reduce((acc, key) => {
      if (selectedDeductible[key].deductible === UNSET_OPTION.deductible) {
        acc[key] = null;
      } else {
        acc[key] = selectedDeductible[key];
      }
      return acc;
    }, {});
    setValue(field.name, filteredSelectedDeductible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeductible]);

  return (
    <>
      {!confirm && (
        <Grid item xs={12} md={4}>
          <Button text="Get Deductible Options" onClick={handleCalculateClick} variant="contained" color="primary" size="small" />
        </Grid>
      )}
      {isLoading && (
        <Grid item xs={12}>
          <Box height={200} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </Grid>
      )}
      {confirm &&
        Object.keys(deductibleOptions).length > 0 &&
        Object.entries(deductibleOptions).map(([key, value]) => (
          <Grid item xs={12} md={4} key={key}>
            <FormSelect
              name={key}
              label={capitalize(key)}
              handleUpdate={handleSelect}
              value={selectedDeductible[key] ?? UNSET_OPTION}
              options={value.map((item) => ({ label: item.deductible, value: item }))}
              rules={{ required: true }}
            />
          </Grid>
        ))}
      {error && (
        <Grid item xs={12}>
          <Empty
            height={200}
            icon={<Error />}
            text="There was an error calculating the deductibles. Please check your inputs and try again."
          />
        </Grid>
      )}
    </>
  );
};

export default Deductibles;
