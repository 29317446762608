// useGetQuotes
import { useApi } from 'hooks';
import api from 'lib/quoteBind/quoteBind.api';

async function getRiskQuotes(id, token) {
  if (!id) {
    throw new Error('useGetQuotes: id is required');
  }

  const apiUrl = `risks/${id}/quotes`;

  const response = await api(token).get(apiUrl);
  return response.data;
}

export function useGetQuotes(queryKey, id, options) {
  return useApi([queryKey, id], async (id, token) => await getRiskQuotes(id, token), {
    cacheTime: 3 * 60 * 1000,
    refetchOnWindowFocus: true,
    ...options,
  });
}
